
export default function FaqComponent() {
    return (
        <div className="flex items-center justify-center mt-10 md:mt-20">
            <div className="">
                <div className="flex flex-col  px-4 ">
                    
                    <div className="space-y-5">
                        <details className="w-full text-left rounded-2xl shadow bg-white">
                            <summary className="py-6 px-6 md:w-[996px] font-semibold">
                            How much does Diaspora cost?
                            </summary>
                            <p className="px-4 py-6 pt-0 ml-4">
                            Diaspora will be available  in the early second quarter of 2024. You can join the waitlist to be notified when we launch.                            </p>
                        </details>
                        <details className="w-full text-left rounded-2xl shadow bg-white">
                            <summary className="py-6 px-6 md:w-[996px] font-semibold">
                            How much does Diaspora cost?
                            </summary>
                            <p className="px-4 py-6 pt-0 ml-4">
                            answer </p>
                        </details>
                        <details className="w-full text-left rounded-2xl shadow bg-white">
                            <summary className="py-6 px-6 md:w-[996px] font-semibold">
                            How much does Diaspora cost?
                            </summary>
                            <p className="px-4 py-6 pt-0 ml-4">
                            answer </p>
                        </details>
                        <details className="w-full text-left rounded-2xl shadow bg-white">
                            <summary className="py-6 px-6 md:w-[996px] font-semibold">
                            How much does Diaspora cost?
                            </summary>
                            <p className="px-4 py-6 pt-0 ml-4">
                            answer </p>
                        </details>
                       
                      
                    </div>
                </div>
            </div>
        </div>
    );
}
import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import FaqComponent from "../../components/Accordion";

const Faq = () => {
  return (
    <div>
      <Navbar />
      <div className="bg-[#F7F7F7] h-[80px]"></div>
      <div className="bg-faq container mx-auto h-[120px] md:h-[396px] bg-contain bg-no-repeat md:bg-cover flex flex-col justify-center">
        <h1 className="text-center mx-auto text-white font-bold text-2xl md:text-6xl ">
        Frequently Asked Questions
        </h1>
      </div>

      <div className="container mx-auto rounded-xl p-10 md:my-12">
      
        <FaqComponent />
      </div>

      <Footer />
    </div>
  );
};

export default Faq;

import { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import { LuChevronDown } from "react-icons/lu";
import Footer from "../../components/Footer";
import WaitlistBg from "../../assets/img/waitlistBg.png";
import axios from "axios";
import { toast } from "react-toastify";

const Waitlist = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    email: "",
    gender: "",
    interestedIn: "",
    phone: "",
    country: "",
    day: "",
    month: "",
    year: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");
        const countryNames = response.data
          .map((country) => country.name.common)
          .sort();
        setCountries(countryNames);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleButtonClick = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const submit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const data = {
      fields: Object.entries(formData).map(([name, value]) => ({
        objectTypeId: "0-1",
        name,
        value,
      })),
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: "I agree to allow Diaspora to store and process my personal data.",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text: "I agree to receive marketing communications from Diaspora.",
            },
          ],
        },
      },
    };

    try {
      const response = await axios.post(
        `https://api.hsforms.com/submissions/v3/integration/submit/47185502/a3afa06a-d5e1-415c-8a2c-d50eff42bb1b`,
        data
      );
      if (response.status === 200) {
        toast.success("Form submitted. Thank you for reaching out to us");
        setFormData({
          firstname: "",
          email: "",
          gender: "",
          interestedIn: "",
          day: "",
          phone: "",
          country: "",
          month: "",
          year: "",
        });
        setIsSubmitting(false);
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="h-full">
      <Navbar />

      <div className="h-[80px]"></div>
      <div className="relative">
        <main className="container mx-auto px-4 py-8 flex flex-col lg:flex-row">
          <div className="lg:w-3/4 lg:pr-8">
            <h1 className="text-5xl font-bold text-gray-800 mb-4">
              Join Waitlist
            </h1>
            <p className="w-3/4 text-gray-500 my-8">
              For questions and inquiries, fill out the form below and we will
              contact you. Love, Team Diaspora.
            </p>
          </div>

          <div className="lg:w-1/2 mt-8 lg:mt-0">
            <div className="bg-white shadow-2xl p-6">
              <form onSubmit={submit}>
                <div className="mb-6">
                  <label className="block font-medium text-gray-700 mb-2">
                    How do you identify?
                  </label>
                  <div className="flex space-x-4">
                    {["Woman", "Man", "Non Binary"].map((option) => (
                      <button
                        key={option}
                        type="button"
                        className={`flex-1 py-2 px- md:px-4 rounded-full border ${
                          formData.gender === option
                            ? "bg-primary text-white"
                            : "bg-white text-gray-700"
                        }`}
                        onClick={() => handleButtonClick("gender", option)}
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                </div>
                <div className="mb-6">
                  <label className="block font-medium text-gray-700 mb-2">
                    Looking to date?
                  </label>
                  <div className="flex space-x-4">
                    {["Women", "Men", "Both"].map((option) => (
                      <button
                        key={option}
                        type="button"
                        className={`flex-1 py-2 px-4 rounded-full border ${
                          formData.interestedIn === option
                            ? "bg-primary text-white"
                            : "bg-white text-gray-700"
                        }`}
                        onClick={() =>
                          handleButtonClick("interestedIn", option)
                        }
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                </div>

                <div className="mb-6">
                  <label
                    htmlFor="email"
                    className="block font-medium text-gray-700 mb-2"
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    placeholder="Enter your email"
                  />
                </div>
                <div className="mb-6">
                  <label
                    htmlFor="firstname"
                    className="block font-medium text-gray-700 mb-2"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="firstname"
                    name="firstname"
                    value={formData.firstname}
                    onChange={handleChange}
                    className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    placeholder="Enter your name"
                  />
                </div>
                <div className="mb-6">
                  <label
                    htmlFor="phone"
                    className="block font-medium text-gray-700 mb-2"
                  >
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    placeholder="Enter your phone number"
                  />
                </div>
                <div className="mb-6">
                  <label
                    htmlFor="country"
                    className="block font-medium text-gray-700 mb-2"
                  >
                    Country
                  </label>
                  <select
                    id="country"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  >
                    <option value="">Select your country</option>
                    {countries.map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-6">
                  <label className="block font-medium text-gray-700 mb-2">
                    Your Birthday
                  </label>
                  <div className="flex space-x-4">
                    <div className="flex-1 relative">
                      <select
                        name="day"
                        value={formData.day}
                        onChange={handleChange}
                        className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm appearance-none"
                      >
                        <option value="">Day</option>
                        {[...Array(31)].map((_, i) => (
                          <option key={i} value={i + 1}>
                            {i + 1}
                          </option>
                        ))}
                      </select>
                      <LuChevronDown
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                        size={20}
                      />
                    </div>
                    <div className="flex-1 relative">
                      <select
                        name="month"
                        value={formData.month}
                        onChange={handleChange}
                        className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm appearance-none"
                      >
                        <option value="">Month</option>
                        {[
                          "Jan",
                          "Feb",
                          "Mar",
                          "Apr",
                          "May",
                          "Jun",
                          "Jul",
                          "Aug",
                          "Sep",
                          "Oct",
                          "Nov",
                          "Dec",
                        ].map((month, i) => (
                          <option key={i} value={month}>
                            {month}
                          </option>
                        ))}
                      </select>
                      <LuChevronDown
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                        size={20}
                      />
                    </div>
                    <div className="flex-1 relative">
                      <select
                        name="year"
                        value={formData.year}
                        onChange={handleChange}
                        className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm appearance-none"
                      >
                        <option value="">Year</option>
                        {[...Array(100)].map((_, i) => {
                          const year = new Date().getFullYear() - i;
                          return (
                            <option key={i} value={year}>
                              {year}
                            </option>
                          );
                        })}
                      </select>
                      <LuChevronDown
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                        size={20}
                      />
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`w-full bg-primary text-white mb-4 py-2 px-4 rounded-full hover:bg-pink-700 flex items-center justify-center ${
                    isSubmitting ? "opacity-75 cursor-not-allowed" : ""
                  }`}
                >
                  {isSubmitting ? (
                    <>
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Submitting...
                    </>
                  ) : (
                    "SUBMIT"
                  )}
                </button>
              </form>
            </div>
          </div>
        </main>
        <div className="absolute -z-10 top-64 ">
          <img src={WaitlistBg} alt="Waitlist Background" />
        </div>
      </div>

      <div className="md:mt-36">
        <Footer />
      </div>
    </div>
  );
};

export default Waitlist;

import PaytentLogo from "../assets/img/DiasporaLogoW.svg";
import { LuInstagram, LuLinkedin } from "react-icons/lu";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <footer className="bg-[#1A1A1A] text-white">
      <div className="container mx-auto hidden md:flex items-start justify-between  py-20 ">
        <div>
          <img src={PaytentLogo} alt="Paytent Logo" />
          <p className="mt-40">Copyright @ 2024 Diaspora. </p>
        </div>
        <div className="flex ">
          <div className="mx-20 flex flex-col">
            <h5 className="font-bold">Company</h5>
            <a href="/" className="opacity-90 mt-8 mb-2">
              About us
            </a>
            <a href="/contact" className="opacity-90 mb-2">
              Contact us
            </a>
            <a href="/" className="opacity-90">
              FAQs
            </a>
          </div>
          <div className="mx-20 flex flex-col">
            <h5 className="font-bold">Socials</h5>
            <a href="/" className="flex gap-2 items-center opacity-90 mt-8 mb-2">
              <LuLinkedin />
              Linkedin
            </a>
            <a href="/" className="flex gap-2 items-center opacity-90 mb-2">
              <FaXTwitter />
              X
            </a>
            <a href="/" className="flex gap-2 items-center opacity-90 mb-2">
              <LuInstagram />Instagram
            </a>
           
          </div>
         
        </div>
      </div>
      <div className="container mx-auto md:hidden flex flex-col items-start justify-between  py-20 ">
        <div className="mb-5">
          <img src={PaytentLogo} alt="Paytent Logo" />
        </div>
        <div className="flex ">
          <div className="m-5 flex flex-col">
            <h5 className="font-bold">Company</h5>
            <a href="/" className="opacity-90 mt-8 mb-2">
              About us
            </a>
            <a href="/contact" className="opacity-90 mb-2">
              Contact us
            </a>
            <a href="/" className="opacity-90">
              FAQs
            </a>
          </div>
          <div className="m-5 flex flex-col">
          <h5 className="font-bold">Socials</h5>
            <a href="/" className="flex gap-2 items-center opacity-90 mt-8 mb-2">
              <LuLinkedin />
              Linkedin
            </a>
            <a href="/" className="flex gap-2 items-center opacity-90 mb-2">
              <FaXTwitter />
              X
            </a>
            <a href="/" className="flex gap-2 items-center opacity-90 mb-2">
              <LuInstagram />Instagram
            </a>
           
          </div>
          
        </div>
            <p className="mt-10">Copyright @ 2024 Diaspora. </p>
      </div>
    </footer>
  );
};

export default Footer;

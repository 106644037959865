// import { useState } from "react";
import Navbar from "../../components/Navbar";
import Privacy from "../../assets/img/privacy.svg";
import Match from "../../assets/img/match.svg";
import Connect from "../../assets/img/connect.svg";
import OnTime from "../../assets/img/onTime.png";
import Swipes from "../../assets/img/swipes.png";
import YourCrew from "../../assets/img/yourCrew.png";
import Soulmate from "../../assets/img/soulmate.png";
import Couple1 from "../../assets/img/couple1.png";
import Couple3 from "../../assets/img/SpeedDate.png";
import Couple2 from "../../assets/img/withSwipe.png";
import Lid from "../../assets/img/lid.png";
// import FaqComponent from "../../components/Accordion";
import Footer from "../../components/Footer";
// import axios from "axios";
// import { toast } from "react-toastify";

const Landing = () => {
  // const [email, setEmail] = useState("");

  // const submit = async (e) => {
  //   e.preventDefault();

  //   const data = {
  //     fields: [
  //       {
  //         objectTypeId: "0-1",
  //         name: "email",
  //         value: email,
  //       },
  //     ],
  //     legalConsentOptions: {
  //       consent: {
  //         consentToProcess: true,
  //         text: "I agree to allow Diaspora to store and process my personal data.",
  //         communications: [
  //           {
  //             value: true,
  //             subscriptionTypeId: 999,
  //             text: "I agree to receive marketing communications from Diapora.",
  //           },
  //         ],
  //       },
  //     },
  //   };


  //   const response = await axios.post(
  //     `https://api.hsforms.com/submissions/v3/integration/submit/47185502/a3afa06a-d5e1-415c-8a2c-d50eff42bb1b`,
  //     data
  //   );
  //   if (response.status === 200) {
  //     toast.success("Form submitted. Thank you for joining our waitlist");
  //   } else {
  //     toast.error("Please fill input fields with (*)");
  //   }

  //   setEmail("");

  //   // window.location.reload();
  // };

  return (
    <div>
      <Navbar />
      <div className="hidden md:block h-[80px]"></div>
      <div id="waitlist" className="bg-img w-screen  h-[249px] md:h-screen bg-contain bg-no-repeat sm:bg-cover flex flex-col justify-end">
        <div className="text-center mb-2 md:mb-12 p-2 md:p-14 text-white">
          <h2 className="mx-auto font-bold text-2xl md:text-7xl md:w-[900px] mt-">
            Love without borders
          </h2>
          <p className="my-2 md:my-8 text-sm md:text-2xl">
            Cheaper than flights but more fulfilling, <br/> ready to explore?
          </p>
          <div>
            <a href="/waitlist" className="bg-primary px-2 py-2 h-9 md:h-12 md:py-3 md:px-6 ms-1 rounded-full text-white hover:scale-[1.02] duration-300">
            Join Waitlist
            </a>

            {/* <form className="md:mt-6">
              <input
                type="email"
                placeholder="Enter your email"
                className="rounded-full ps-4 w-54 h-9 md:h-12 md:w-80 text-secondary"
                onChange={(e) => setEmail(e.target.value)}
                required
              />

              <button
                onClick={submit}
                className="bg-primary px-2 h-9 md:h-12 md:py-3  md:px-6 ms-1 rounded-full text-white hover:scale-[1.02] duration-300"
              >
                Join Waitlist
              </button>
            </form> */}
          </div>
        </div>
      </div>

      <section className="my-16 container mx-auto">
        <div className="grid md:grid-cols-2 gap-10">
          <div className="flex flex-col justify-end">
            <div></div>
            <div className="md:mb-12 lg:w-4/5">
              <h2 className="text-2xl lg:text-5xl mb-12 leading-tight">
                The first dates are always the hardest but we have made it
                easier.
              </h2>

              <a
                href="/waitlist"
                className="bg-primary py-2 px-8 rounded-full text-white hover:opacity-70 duration-300"
              >
                Join Waitlist
              </a>
            </div>
          </div>
          <div>
            <img src={Couple1} alt="" />
          </div>
        </div>
      </section>

      <section className="py-20 text-secondary">
        <h2 className="text-center px-2 text-3xl md:text-4xl font-medium">
          Our <span className="text-primary font-semibold">Diaspora</span>{" "}
          benefit
        </h2>
        <div className="container p-4 md:p-20 justify-center grid md:grid-cols-3 gap-4 m-auto">
          <div className="p-4">
            <img src={Privacy} alt="privacy icon" />
            <p className="font-semibold text-3xl mt-8">Safe & Secure</p>
            <p className="opacity-50 mt-4">
              A safe haven for lovers. With thorough identity checks for profile
              verification, you can find love in peace.{" "}
            </p>
          </div>
          <div className="p-4">
            <img src={Match} alt="privacy icon" />
            <p className="font-semibold text-3xl mt-8">Find your "Crew"</p>
            <p className="opacity-50 mt-4">
              Home is where the heart is even when you are away. Connect with
              people, just like you.
            </p>
          </div>
          <div className="p-4">
            <img src={Connect} alt="privacy icon" />
            <p className="font-semibold text-3xl mt-8">Connect Worldwide</p>
            <p className="opacity-50 mt-4">
              Connect across borders with users from every country on the
              waitlist. Find love in the diaspora with just a click.
            </p>
          </div>
        </div>
      </section>


      <section className="text-secondary">
        <div className="py-16 container mx-auto grid md:grid-cols-2 gap-10">
          <div>
            <img src={Couple3} alt="" />
          </div>
          <div className="flex flex-col justify-center">
            <div className="md:w-3/4">
              <h2 className="text-4xl md:text-5xl font-bold leading-tight">
                Speed date like a pro.
              </h2>

              <p className="text-xl my-8">
              Set a timer on love with our virtual video matched experience. 2 minutes to say yay or nay with potential matches from around the world, Ready to explore ?
              </p>

              <a
                href="/features"
                className="bg-primary py-2 px-8 rounded-full text-white hover:opacity-70 duration-300"
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-primary text-white">
        <div className="py-16 container mx-auto grid md:grid-cols-2 gap-10">
          <div className="flex flex-col justify-center">
            <div className="md:w-3/4">
              <h2 className="text-4xl md:text-5xl font-bold leading-tight">
                It starts with a swipe
              </h2>

              <p className="text-xl my-8">
                Connect exactly how you want with our specially built suite of
                tools. Video calls, audio calls and even love letters for the
                true sweethearts.{" "}
              </p>

              <a
                href="/features"
                className="bg-white py-2 px-8 rounded-full text-secondary hover:opacity-70 duration-300"
              >
                Learn More
              </a>
            </div>
          </div>
          <div>
            <img src={Couple2} alt="" />
          </div>
        </div>
      </section>

      <section className="py-16">
        <div className="grid md:grid-cols-2 gap-10">
          <div>
            <img src={Soulmate} alt="" />
          </div>
          <div className="p-4 md:p-0 flex flex-col justify-center">
            {/* <div></div> */}
            <div className="md:w-[80%]">
              <h2 className="text-4xl md:text-5xl font-bold leading-tight">
                Finding Love on{" "}
                <span className="text-primary font-semibold">Diaspora</span>{" "}
              </h2>
              <p className="text-xl my-8">
                The heart knows no boundaries, and neither should you. See what
                we have built to shorten the distance between you and true love.
              </p>
              <a
                href="/features"
                className="bg-primary py-2 px-8 rounded-full text-white hover:opacity-70 duration-300"
              >
                Take a peek
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-[#1A1A1A] my-12">
        <div className="h-full p-8 md:p-20 bg-jigsaw">
          <h2 className="text-4xl font-medium text-white leading-tight">
            An Explorer's Guide to <br />{" "}
            <span className="text-primary font-semibold">Diaspora</span>{" "}
          </h2>
          <div className="grid md:grid-cols-3 gap-10 m-auto text-secondary mt-14">
            <div className="bg-white px-7 pt-6 text-center rounded-3xl">
              <p className="text-2xl font-semibold">Swipes</p>
              <p className="text-sm opacity-50 mt-3">
                Find love with a flick. right swipes to match, left to keep
                exploring.
              </p>
              <img src={Swipes} alt="" className="mx-auto mt-8" />
            </div>
            <div className="bg-white px-7 pb-6 text-center rounded-3xl">
              <img src={YourCrew} alt="" className="mx-auto" />
              <p className="text-2xl font-semibold mt-8">Pick your crew</p>
              <p className="text-sm opacity-50 mt-3">
                An adventure you don't have to do alone. set your preferences
                and we will spin you a match.
              </p>
            </div>
            <div className="bg-white px-7 pt-6 text-center rounded-3xl">
              <p className="text-2xl font-semibold">Right On Time</p>
              <p className="text-sm opacity-50 mt-3">
                You'll never fly solo, get notified when matches come online.
              </p>
              <img src={OnTime} alt="" className="mx-auto mt-8" />
            </div>
          </div>
        </div>
      </section>

      <section className="container mx-auto p-0 rounded-3xl bg-gradient-to-b from-primary to-[#863D4A] text-white">
        <div className="my-14 md:ps-8 flex flex-col md:flex-row justify-between">
            <div className="pt-16 px-6">
              <h2 className="text-3xl md:text-5xl font-bold leading-tight">
              Join waitlist
              </h2>

              <p className="my-8 md:w-3/4">
              For questions and inquiries, fill out the form below and we will contact you. Love, Team Diaspora.
              </p>

              <div>
              <a href="/waitlist" className="bg-white px-2 h-9 md:h-12 py-3  md:px-6 rounded-full text-secondary hover:scale-[1.02] duration-300">
            Join Waitlist
            </a>
            {/* <form className="md:mt-6 mb-6">
              <input
                type="email"
                placeholder="Enter your email"
                className="rounded-full ps-4 w-52 h-9 md:h-12 md:w-80 text-secondary"
                onChange={(e) => setEmail(e.target.value)}
                required
              />

              <button
                onClick={submit}
                className="bg-primary px-2 h-9 md:h-12 md:py-3  md:px-6 ms-1 rounded-full text-white hover:scale-[1.02] duration-300"
              >
                Join Waitlist
              </button>
            </form> */}
          </div>
            </div>
         
          <div>
            <img src={Lid} alt="" className="w-[600px]"/>
          </div>
        </div>
      </section>

      {/* <section id="faq">
        <div className="text-secondary text-center container mx-auto mb-32">
          <h1 className="font-bold text-4xl md:text-5xl ">
            Frequently asked questions
          </h1>
          <div>
            <FaqComponent />
          </div>
        </div>
      </section>  */}

      <Footer />
    </div>
  );
};

export default Landing;

import React from "react";
import Navbar from "../../components/Navbar";
import Superlike from "../../assets/img/prioritylike.png";
import PriorityAisle from "../../assets/img/PriorityAisle.png";
import Cruise from "../../assets/img/cruise.png";
import Takeoff from "../../assets/img/takeoff.png";
import Loveletter from "../../assets/img/LoveLetter.png";
import Crew from "../../assets/img/crew.png";
import Footer from "../../components/Footer";
import Lid from "../../assets/img/lid.png";

const Features = () => {
  return (
    <div>
      <Navbar />
      <div className="bg-features w-screen h-[252px] md:h-screen bg-contain bg-no-repeat md:bg-cover flex flex-col justify-end">
        <div className="text-center p-2 md:p-14 text-white">
          <h1 className="mx-auto font-bold text-2xl md:text-6xl md:w-[900px] mt-">
          Your Passport to the World is Ready
          </h1>
          <p className="my-2 md:my-8 text-sm md:text-2xl">
          We've packed all the essentials for the flight <br/>of your life, and the destination is Love.
          </p>
          <div className="mb-2">
          <a href="/waitlist" className="bg-primary px-2 py-2 h-9 md:h-12 md:py-3 md:px-6 ms-1 rounded-full text-white hover:scale-[1.02] duration-300">
            Join Waitlist
            </a>
            </div>
        </div>
      </div>

      <section className="container mx-auto">
        <div className="my-8 md:my-16">
          <h1 className="text-center font-bold text-2xl md:text-5xl">
            Here’s what we have for you
          </h1>
        </div>

        <div className="my-12 ">
          <div className="flex justify-between flex-wrap gap-4">
          <div className="p-14 bg-primary rounded-3xl text-white w-full max-w-[590px] text-center">
              <img src={Cruise} alt="lover's cruise" className="mx-auto" />
              <h1 className="font-bold text-3xl mt-4">Cruise</h1>
              <p className="mt-6 md:w-3/4 mx-auto">
              Enjoy your quest to find 'The One' with two-minute virtual speed dating rounds.              </p>
            </div>
            <div className="p-14 bg-[#FADBE1] rounded-3xl text-secondary w-full max-w-[590px] text-center">
              <img src={Crew} alt="crew" className="mx-auto" />
              <h1 className="font-bold text-3xl mt-4">Crew</h1>
              <p className="mt-6 md:w-3/4 mx-auto">
              Filter your search to connect with amazing people who share your culture and values.
              </p>
            </div>
            
            
          </div>
          <div className="my-4 md:my-8 flex justify-between flex-col-reverse md:flex-row flex-wrap gap-4">
          <div className="p-14 bg-[#FADBE1] rounded-3xl text-secondary w-full max-w-[590px] text-center">
              <img src={PriorityAisle} alt="Priority Aisles" className="mx-auto" />
              <h1 className="font-bold text-3xl mt-4">Priority Aisles</h1>
              <p className="mt-6 text- w-3/4 mx-auto">
              An organized section just so you can keep track of those big gestures.
              </p>
            </div>
            <div className="p-14 bg-primary rounded-3xl text-white w-full max-w-[590px] text-center">
              <img src={Loveletter} alt="love letter" className="mx-auto" />
              <h1 className="font-bold text-3xl mt-4">Love Letter</h1>
              <p className="mt-6 md:w-3/4 mx-auto">
              Standout with a personalised message before you match.              </p>
            </div>
           
          </div>
          <div className="flex justify-between flex-wrap gap-4">
          <div className="p-14 bg-primary rounded-3xl text-white w-full max-w-[590px] text-center">
              <img src={Takeoff} alt="Takeoff" className="mx-auto" />
              <h1 className="font-bold text-3xl mt-4">Takeoff</h1>
              <p className="mt-6 md:w-3/4 mx-auto">
              Boost your profile visibility for increased matches and stand a better chance at love              </p>
            </div>
          <div className="p-14 bg-[#FADBE1] rounded-3xl text-secondary w-full max-w-[590px] text-center">
              <img src={Superlike} alt="super like" className="mx-auto" />
              <h1 className="font-bold text-3xl mt-4">Priority Like</h1>
              <p className="mt-6 md:w-3/4 mx-auto">
                Interested in someone? get their attention with a priority like.
              </p>
            </div>
            
          </div>


          {/* <div className="flex gap-16">
            <div className="p-10 bg-primary rounded-3xl text-white w-[620px] text-center">
              <img src={Superlike} alt="super like" className="mx-auto" />
              <h1 className="font-bold text-2xl mt-4">Super Like</h1>
              <p>
                interested in someone? get their attention with a priority like.
              </p>
            </div>
            <div className="p-10 bg-primary rounded-3xl text-white w-[620px] text-center">
              <img src={Superlike} alt="super like" className="mx-auto" />
              <h1 className="font-bold text-2xl mt-4">Super Like</h1>
              <p>
                interested in someone? get their attention with a priority like.
              </p>
            </div>
          </div> */}
        </div>
      </section>

      <section className="container mx-auto p-0 rounded-3xl bg-gradient-to-b from-primary to-[#863D4A] text-white">
        <div className="my-14 md:ps-8 flex flex-col md:flex-row justify-between">
            <div className="pt-16 px-6">
              <h2 className="text-3xl md:text-5xl font-bold leading-tight">
              Join waitlist
              </h2>

              <p className="my-8 md:w-3/4">
              For questions and inquiries, fill out the form below and we will contact you. Love, Team Diaspora.
              </p>

              <div>
              <a href="/waitlist" className="bg-white px-2 h-9 md:h-12 py-3  md:px-6 rounded-full text-secondary hover:scale-[1.02] duration-300">
            Join Waitlist
            </a>
          </div>
            </div>
         
          <div>
            <img src={Lid} alt="" className="w-[600px]"/>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Features;

import Contact from "./pages/contact/contact";
import Faq from "./pages/faq/faq";
import Features from "./pages/features/features";
import Landing from "./pages/landing/landing";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Waitlist from "./pages/waitlist/waitlist";

const { Routes, Route } = require("react-router-dom");

function App() {
  return (
     <>
     <ToastContainer />
     <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/features" element={<Features />} />
          <Route path="/waitlist" element={<Waitlist />} />

          {/* <Route path="/about" element={<Aboutus />} />
         */}

        </Routes>
    </>
  );
}

export default App;

import { useState } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import axios from "axios";
import { toast } from "react-toastify";

const Contact = () => {
  const [email, setEmail] = useState("");
  const [fullname, setFullname] = useState("");
  const [message, setMessage] = useState("");

  const submit = async (e) => {
    e.preventDefault();

    const data = {
      fields: [
        {
          objectTypeId: "0-1",
          name: "fullname",
          value: fullname,
        },
        {
          objectTypeId: "0-1",
          name: "email",
          value: email,
        },
        {
          objectTypeId: "0-1",
          name: "message",
          value: message,
        },
      ],
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: "I agree to allow Diapora to store and process my personal data.",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text: "I agree to receive marketing communications from Diapora.",
            },
          ],
        },
      },
    };


    const response = await axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/47185502/8750d175-fc68-48b1-b0d5-053234d98b8d`,
      data
    );
    if (response.status === 200) {
      toast.success("Form submitted. Thank you for reaching out to us");
    } else {
      toast.error("Please fill input fields with (*)");
    }

    setEmail("");

    // window.location.reload();
  };

  return (
    <div>
      <Navbar />
      <div className="hidden md:block bg-[#F7F7F7] h-[80px]"></div>
      <div className="bg-contact container mx-auto h-[120px] md:h-[396px] bg-contain bg-no-repeat md:bg-cover flex flex-col justify-center">
        <h1 className="text-center mx-auto text-white font-bold text-2xl md:text-6xl ">
          Contact Us
        </h1>
      </div>

      <div className="container mx-auto bg-[#F2F2FD] rounded-xl p-10 md:my-20">
        <p className="text-center">
        For questions and inquiries, fill out the form below and we will contact you. Love, Team Diaspora.
        </p>

        <form className="gap-4 mt-16 flex flex-col items-center w-full md:w-1/2 mx-auto">
          <input
            type="text"
            placeholder="Full name"
            className="border ps-3 w-full h-12"
            onChange={(e) => setFullname(e.target.value)}
          />
          <input
            type="email"
            placeholder="Email"
            className="border ps-3 w-full h-12"
            onChange={(e) => setEmail(e.target.value)}
          />
          <textarea
            id="details"
            placeholder="Message"
            className="p-4 w-full border h-[220px]"
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
          <button onClick={submit} className="bg-primary text-white py-4 w-full rounded-full">
            SUBMIT
          </button>
        </form>
      
      {/* <div className="text-center h-screen bg-[#F2F2FD] p-2 md:p-14 text-white"></div> */}

      </div>

      <Footer />
    </div>
  );
};

export default Contact;
